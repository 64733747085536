<template>
	<div>		
		<router-view>
		</router-view>
	</div>
</template>
<script lang="js">
	export default {
		name: 'sitetemplate',

		methods: {
			async logout() {
				await this.$store.dispatch('logout')
				await this.$router.push('/')
			}
		}
	}
</script>